.group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cardContainer {
    margin: 10px !important;
    text-align: start !important;
}

.card {
    width: 345px;
    max-width: 345px;
    flex-grow: 1;
}