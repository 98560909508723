.home {
    margin: -80px -0.75rem 0;
}

.head {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 2em;
    background-color: #2c3e50;
    height: 20vw;
}

.headline {
    font-size: 5.9vw;
    font-weight: 600;
    width: 85%;
    font-family: inherit;
    color: white;
}

.support-text {
    width: 50%;
    font-size: 1.4vw;
}

.body {
    background-color: #ececf1;
    padding: 10px;
}