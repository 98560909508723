.star-over {
    color: #fdcb6e;
    transition: .2s;
}

.star-over:hover {
    filter: brightness(.5);
}

.star-placeholder {
    transition: .2s;
}

.star-placeholder:hover {
    filter: brightness(2);
}