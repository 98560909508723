.userboard {
    border-radius: 7px;
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.2);
    flex-basis: 50%;
    margin-bottom: 10px;
}

.ubcontent {
    padding: 10px;
}

.ubimage {
    width: 7em;
    height: 7em;
    align-self: center;
    margin-right: 10px;
}